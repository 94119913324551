let Balance = function(tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr

    /**
     * 余额列表
     * @param type
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param keyWord
     * @param success
     * @param error
     * @constructor
     */
    this.BalanceList = function( type, startTime, endTime, pageIndex, keyWord, success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/AssetsBalance'
        if (type) {
            url += `/${type}`;
        }
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null, function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

    /**
     * 总后台---获取余额明细
     * @param assetsId
     * @param startTime
     * @param endTime
     * @param pageIndex
     * @param success
     * @param error
     * @constructor
     */
    this.BalanceDetail = function(startTime, endTime,keyWord,type, pageIndex, pageSize,success, error) {
        var url = ServiceBaseAddress + '/api/Distributor/OrgAssetsDetails'
        
        if (startTime) {
            url += `/${startTime}`;
        }
        else {
            url += '/%20';
        }
        if (endTime) {
            url += `/${endTime}`;
        }
        else {
            url += '/%20';
        }
        if (keyWord) {
            url += `/${keyWord}`;
        }else {
            url += '/%20';
        }
        if (type) {
            url += `/${type}`;
        }
        if (pageIndex) {
            url += `/${pageIndex}`;
        }
        if (pageSize) {
            url += `/${pageSize}`;
        }
        TokenClient.Get(url, true, null, null, function(data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }

}

export { Balance }
